import { Component, OnInit } from '@angular/core';
import { TarotistaService } from '../tarotista.service';
import { Tarotistas } from '../tarotistas';
import { Contacto, Email } from '../contacto';

import { ResizedEvent } from 'angular-resize-event';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  
    model = new Contacto;
    susEmail: string;
    

    // model: any = {};
 
  onSubmit() {
    if(this.captcha){
      
      $('#modal-register').modal('hide');
      // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model))
      this.enviarContacto();
    }
  }

  public captcha = false;

  title = 'MaestrasDelTarot';

  tarotistas: Tarotistas[];
  emitiendo: Tarotistas[];
  mostrar_emitiendo: boolean = false;
  urlIf: string = "";

  lang = "es";
  size = "normal";
  theme = "light";
  type = "image";
  badge = "";
  

  constructor(private tarotistaService: TarotistaService) { }

  ngOnInit() {
    
    this.getTarotistas();
    this.getTarotistasEmitiendo();
  }

  addSuscripcion(): void {
    
    
    let email = new Email;
    
    email.email = this.susEmail;

    this.tarotistaService.addSuscripcion(email)
      .subscribe(data => {
        // console.log("addSuscripcion: " + data);
      })
  }

  enviarContacto(): void {
    this.tarotistaService.enviarContacto(this.model)
      .subscribe(data => {
        // console.log(data);
      })
  }

  getTarotistas(): void {
    this.tarotistaService.getTarotistas()
      .subscribe(data => this.tarotistas = data);
  }

  getTarotistasEmitiendo(): void {
    this.tarotistaService.getTarotistasEmitiendo()
      .subscribe(data => {
        this.emitiendo = data;
        if(data.length > 0){
          this.mostrar_emitiendo = true;  
          this.urlIf = "http://p.andalmedia.com/apis/streamingif.php?id="+data[0].id_perfil;
        } else {
          this.mostrar_emitiendo = false;  
        }
        
      });
  }

  EnviarContacto(): void {
    //console.log("Enviar");
  }

  handleExpire() {
    this.captcha = false;
    //console.log("handleExpire");
  }

  handleLoad() {
    //console.log("handleLoad");
  }

  handleSuccess(event: string) {
    this.captcha = true;
    //console.log("handleSuccess");
  }

  toggleTitle() {
    $("body").toggleClass("navbar-search-open");
  }

  onResized(event: ResizedEvent) {
    let alto = event.newWidth * 0.545;
    $("#player").height(alto);
  }

}
