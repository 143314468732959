import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TarotistaComponent } from './tarotista/tarotista.component';
import { CapitalizePipe } from './capitalize.pipe';
import { EncodeuriPipe } from './encodeuri.pipe';

import { AppRoutingModule } from './/app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { NgxCaptchaModule } from 'ngx-captcha';
import { SafePipe } from './safe.pipe';
import { KeyobjectPipe } from './keyobject.pipe';

import { AngularResizedEventModule } from 'angular-resize-event';

// import { RecaptchaModule } from 'ng-recaptcha';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TarotistaComponent,
    CapitalizePipe,
    EncodeuriPipe,
    SafePipe,
    KeyobjectPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularResizedEventModule,
    NgxCaptchaModule.forRoot({
      reCaptcha2SiteKey: '6LctGNUSAAAAAAtClFuVszozn1pjqio8hhqgaafR',
      invisibleCaptchaSiteKey: '6LctGNUSAAAAAAtClFuVszozn1pjqio8hhqgaafR',
    }),
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
