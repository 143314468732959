import { Component, OnInit } from '@angular/core';
import { TarotistaService } from '../tarotista.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Tarotista } from '../tarotista';
import { Comentario } from '../contacto';
import { Email } from '../contacto';


import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var jquery: any;
declare var $: any;

declare var videojs: any;

@Component({
  selector: 'app-tarotista',
  templateUrl: './tarotista.component.html',
  styleUrls: ['./tarotista.component.css']
})

export class TarotistaComponent implements OnInit {

  Arr = Array;
  horas: number = 24;
  susEmail: string;
  
  model = new Comentario;

  public player: any;
  public tarotista: Tarotista;
  public comentarios: Comentario[];
  public captcha = false;
  public emitiendo = false;
  public urlIf: SafeResourceUrl;
  public horario2:number [][] = [[],[],[],[],[],[],[]];
  

  onSubmit() {
    if (!this.captcha) {
      const id = + this.route.snapshot.paramMap.get('id');
      // console.log(this.model);
      this.model.id_perfil = id;
      $('#modal-register').modal('hide');
      // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model))
      this.enviarComentario();
    }
  }


  constructor(private route: ActivatedRoute, private router: Router, private tarotistaService: TarotistaService) {
  }



  ngOnInit() {
    const id = + this.route.snapshot.paramMap.get('id');
    this.getTarotista(id);
    this.getComentarios(id);
  }

  addSuscripcion(): void {
    
    
    let email = new Email;
    
    email.email = this.susEmail;

    this.tarotistaService.addSuscripcion(email)
      .subscribe(data => {
        // console.log("addSuscripcion: " + data);
      })
  }

  getTarotista(id: number): void {
    this.tarotistaService.getTarotista(id)
      .subscribe(data => {
        
        this.tarotista = data;
        let hora: number = 0;
        
        this.tarotista.horarios.forEach(horas => {
          hora++;
          
          for (let dia in [1, 2, 3, 4, 5, 6, 0]) {
            if (horas.toString().indexOf(dia) > -1) {
              this.horario2[dia].push(hora-1);
            }
            else
            {
              this.horario2[dia].push(null);
            }
          }
          
        });
        

        if (data.emitiendo == 1) {
          this.emitiendo = true;
          this.urlIf = "http://p.andalmedia.com/apis/streamingif.php?id=" + id;
        }
        else {
          this.emitiendo = false;
        }
      });
  }

  getComentarios(id: number): void {
    this.tarotistaService.getComentarios(id)
      .subscribe(data => {
        this.comentarios = data;
        
      })
  }

  enviarComentario(): void {
    this.tarotistaService.addComentario(this.model)
      .subscribe(data => {
        // console.log(data);
      })
  }

}
