export class Contacto {
  nombre: string;
  email: string;
  telefono: string;
  mensaje: string;
}

export class Email {
  email: string;
}

export class Comentario {
  id_perfil: number;
  nombre: string;
  email: string;
  comentario: string;
}