import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams,HttpResponse, HttpSentEvent,} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Tarotista } from './tarotista'
import { Tarotistas } from './tarotistas'
import { Contacto, Email, Comentario } from './contacto'


@Injectable({
  providedIn: 'root'
})

export class TarotistaService {
   
  constructor(private http: HttpClient) { }

  getTarotista(id: number): Observable<Tarotista> {
    let apiURL = 'https://p.andalmedia.com/rest/mdt_v1.php/Perfiles/'+id;
    
    return this.http.get<Tarotista>(apiURL)
      .pipe(
        tap(tarotista => this.log(`fetched tarotista id:`+id)),
        catchError(this.handleError('getTarotistas'))  
      );
  }
  
  getTarotistas(): Observable<Tarotistas[]> {
    let apiURL = 'https://p.andalmedia.com/rest/mdt_v1.php/Perfiles';
    
    return this.http.get<Tarotistas[]>(apiURL)
    .pipe(
      tap(tarotistas => {
        this.log(`fetched tarotistas`);
      }),
      // tap(tarotistas => this.log(`fetched tarotistas`)),
      catchError(this.handleError('getTarotistas', []))
    );
  }

  getComentarios(id: number): Observable<Comentario[]> {
    let apiURL = 'https://p.andalmedia.com/rest/mdt_v1.php/Comentarios/'+id;
    return this.http.get<Comentario[]>(apiURL)
    .pipe(
      tap(comentarios => this.log(`fetched comentarios`)),
      catchError(this.handleError('getComentarios', []))
    );

  }
  
  getTarotistasEmitiendo(): Observable<Tarotistas[]> {
    let apiURL = 'https://p.andalmedia.com/rest/mdt_v1.php/PerfilesEmitiendo';
    
    return this.http.get<Tarotistas[]>(apiURL)
    .pipe(
      tap(tarotistasEmitiendo => this.log(`fetched tarotistasEmitiendo`)),
      catchError(this.handleError('getTarotistasEmitiendo', []))
    );
  }

  enviarContacto(contacto: Contacto): Observable<Contacto> {
    let apiURL = 'https://p.andalmedia.com/rest/mdt_v1.php/EnviarContacto';
    return this.http.post<Contacto>(apiURL, contacto)
      .pipe(
        tap(contacto => this.log(`posted contacto`)),
        catchError(this.handleError('addContacto', contacto))
      );
  }

  addComentario(comentario: Comentario): Observable<Comentario> {
    let apiURL = 'http://p.andalmedia.com/rest/mdt_v1.php/Comentarios';
    return this.http.post<Comentario>(apiURL, comentario)
      .pipe(
        tap(comentario => {
          //console.log("Proc " + comentario);
          this.log(`posted comentario` + comentario);
        }),
        catchError(this.handleError('addComentario', comentario))
      );
  }


  addSuscripcion(email: Email): Observable<Email> {
    let apiURL = 'http://p.andalmedia.com/rest/mdt_v1.php/AgregarSuscripcion';
    return this.http.post<Email>(apiURL, email)
      .pipe(
        tap(email => {
          // console.log("Proc "+email);
          this.log(`posted email` + email);
        }),
        catchError(this.handleError('enviarSuscripcion', email))
      );
  }


  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
 
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    //console.log('TarotistaService: ' + message);
  }

}
