import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeuri'
})
export class EncodeuriPipe implements PipeTransform {

  transform(uris: any, attribute: string): any {
    if (uris === undefined) { return ''; }
    uris = uris.replace(" ","_");
    return encodeURI(String(uris)).toLowerCase();
  }

}
